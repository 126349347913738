<template>
  <div>
    <a-form class="offset-lg" :label-col="{ lg: { span: 24 },xl:{ span: 6 } }" :wrapper-col="{lg: { span: 24 },xl:{ span: 12 } }">
      <a-form-item label="SVG-карта">

        <!-- Map -->
        <div class="map-container" v-if="mapUrl">
          <a-tooltip>
            <template #title>
              Удалить файл
            </template>
            <a-button type="dashed" @click="deleteImage">
              <template #icon>
                <close-outlined/>
              </template>
            </a-button>
          </a-tooltip>
          <img :src="mapUrl" alt="Карта">
        </div>
        <a-button type="dashed" :loading="loadingProgress" @click="$refs.fileToUpload.click()" v-else>
          <template #icon>
            <plus-circle-outlined/>
          </template>
          Добавить файл
        </a-button>
        <input type="file" ref="fileToUpload" style="display: none" @change="uploadImage">

      </a-form-item>
      <a-table :columns="columns" :data-source="mapObjects"
               :locale="{ emptyText: 'Нет данных' }" :pagination="{ hideOnSinglePage: true, pageSize: 999 }"
               v-if="!hideObjects">
        <template #objectName="{ record }">
          <object-tree-select v-model="record.objectId" :parent-id="parentId"
                              class="w-100" max-height="210px" display-as-name="name" disable-root
                              @change="onObjectSelected($event, record)"/>
        </template>
        <template #mapObjectId="{ record }">
          <a-input v-model:value="record.mapObjectId" placeholder="Введите идентификатор" class="w-100"/>
        </template>
        <template #actionsTitle>
          <a-button type="dashed" @click="addMapObject()">
            <template #icon>
              <plus-circle-outlined/>
            </template>
            Добавить <br> объект
          </a-button>
        </template>
        <template #actions="{ index }">
          <a-button type="dashed" @click="deleteMapObject(index)">
            <template #icon>
              <close-outlined/>
            </template>
          </a-button>
        </template>
      </a-table>
    </a-form>

    <div class="svg-map" id="svgMap" ref="svgMapRef" v-if="svgMapContent" :style="{ display: 'none' }">
      <div v-html="svgMapContent"></div>
    </div>

  </div>
</template>

<script>
import { ref, reactive, computed } from "@vue/reactivity";
import { nextTick } from "@vue/runtime-core";
import {
  PlusCircleOutlined,
  CloseOutlined,
} from '@ant-design/icons-vue';
import { MAP_PARAM } from "@/constants/common";
import { fileHelper } from "@/compositions/commonFunctions";
import { notification } from "ant-design-vue";
import { resourcesData } from "@/compositions/resources";
import ObjectTreeSelect from "@/components/admin/objects/ObjectTreeSelect";

export default {
  name: 'SvgMapEditor',
  props: {
    url: String,
    objects: Array,
    hideObjects: Boolean,
    parentId: { default: 'root' },
  },
  emits: ['update:url'],
  setup(props, { emit }) {
    const svgMapContent = ref('');

    const fileToUpload = ref('fileToUpload');
    const mapObjects = reactive(props.objects);
    const loadingProgress = ref(false);
    const { uploadFile, downloadFile } = resourcesData();

    const mapUrl = computed({
      get: () => props.url,
      set: (value) => emit('update:url', value)
    });
    const columns = [
      {
        title: 'Объект',
        width: '40%',
        slots: { customRender: 'objectName' },
      },
      {
        title: 'Код',
        ellipsis: true,
        className: 'text-break',
        dataIndex: 'objectCode',
      },
      {
        title: 'Идентификатор на карте',
        slots: { customRender: 'mapObjectId' },
      },
      {
        className: 'text-center',
        slots: { customRender: 'actions', title: 'actionsTitle' },
        width: 140,
      },
    ];

    function addMapObject(mapObjectId = '') {
      mapObjects.push({
        mapObjectId,
        objectCode: '',
        objectId: undefined,
      });
    }

    function deleteMapObject(index) {
      mapObjects.splice(index, 1);
    }

    async function uploadImage(e) {
      const files = e.target?.files;
      if (files && files[0]) {
        console.log(files[0]);

        if (files[0]?.type !== 'image/svg+xml') {
          return notification.error({
            message: 'Ошибка загрузки',
            description: 'Допустимо загружать только SVG-изображения.',
          });
        }
        loadingProgress.value = true;
        try {
          const previewImageBase64 = await fileHelper.createBase64FromFile(files[0]);
          const previewImage = await uploadFile(previewImageBase64, 'objectMap');
          mapUrl.value = previewImage?.url;
          await parseMapItems(previewImage?.url);

        } catch (e) {
          notification.error({ message: 'Ошибка', description: 'Не удалось загрузить карту' });
        } finally {
          loadingProgress.value = false;
        }
      }
    }

    async function parseMapItems(url) {
      svgMapContent.value = await downloadFile(url);
      nextTick(() => nextTick(() => {
        const svgDoc = document.getElementById("svgMap");
        const mapPins = svgDoc?.querySelectorAll(`*[${MAP_PARAM.ID_MAP_PIN}]`);
        const objects = [];
        mapPins.forEach((item) => {
          objects.push(item.getAttribute(MAP_PARAM.ID_MAP_PIN));
        });
        objects.sort().forEach((item) => {
          addMapObject(item);
        });
      }));
    }

    function deleteImage() {
      mapUrl.value = '';
      fileToUpload.value.value = null;
    }

    function onObjectSelected(object, record) {
      record.objectCode = object?.code || null;
    }

    return {
      fileToUpload, svgMapContent,
      mapUrl, mapObjects, columns, loadingProgress,
      addMapObject, deleteMapObject, uploadImage, deleteImage,
      onObjectSelected,
    }
  },
  components: { ObjectTreeSelect, PlusCircleOutlined, CloseOutlined },
}
</script>

<style lang="less">
.map-container {
  position: relative;
  display: inline-block;
  padding-right: 2.5rem;

  .ant-btn {
    position: absolute;
    top: -0.5rem;
    right: 0;
  }

  img {
    max-width: 520px;
  }
}
</style>
