<template>
  <section>
    <a-breadcrumb>
      <a-breadcrumb-item>
        <router-link :to="{ name: 'objects' }">Структура</router-link>
      </a-breadcrumb-item>
      <a-breadcrumb-item>SVG-карта корня</a-breadcrumb-item>
    </a-breadcrumb>

    <a-page-header title="SVG-карта корня" @back="() => $router.push({name: 'objects'})">
      <template #extra>
        <a-button key="cancel" size="large">
          <router-link :to="{ name: 'objects' }">Отменить</router-link>
        </a-button>
        <a-button key="save" size="large" type="primary" @click="handleSubmit"
                  :disabled="!isFormChanged('map')">
          Сохранить
        </a-button>
      </template>
    </a-page-header>

    <a-spin v-if="loading"/>
    <svg-map-editor v-else v-model:objects="formMap.mapObjects" v-model:url="formMap.mapImage"/>

  </section>
</template>

<script>
import { ref, unref } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import { notification } from "ant-design-vue";
import { getOneData, requestAPI } from "@/compositions/objects";
import { keepUnsavedChangesManager } from "@/compositions/keepUnsavedChangesManager";
import { validatorFunctions } from "@/compositions/validators";
import SvgMapEditor from "@/components/admin/objects/SvgMapEditor";

export default {
  name: "ObjectsSvgMapPage",
  components: { SvgMapEditor },
  setup() {
    const loading = ref(true);

    const { formMap } = getOneData();
    const { getObjectById, updateObject } = requestAPI();
    const { useKeepManager, fixChanges, isFormChanged } = keepUnsavedChangesManager();
    const { svgMapValidator } = validatorFunctions();

    onMounted(async () => {
      loading.value = true;
      const res = await getObjectById('root');
      formMap.value.mapImage = res.mapUrl;
      formMap.value.mapObjects = res.mapObjects;

      useKeepManager({ 'map': formMap });
      loading.value = false;
    });

    async function handleSubmit() {
      const requestForm = unref(formMap);
      if (!requestForm.mapImage) requestForm.mapImage = null;

      // Проверка заполнены ли идентификаторы карты корректно
      if (!await svgMapValidator(requestForm.mapObjects)) {
        return notification.error({
          message: 'Проверьте данные',
          description: 'В таблице есть незаполненные поля',
        });
      }

      try {
        const res = await updateObject(requestForm, 'root');
        if (res?.id) {
          notification.success({ message: 'Успех', description: 'Изменения сохранены' });
          fixChanges();
        } else {
          notification.error({ message: 'Ошибка', description: 'Сохранение карты не удалось' });
        }
      } catch (error) {
        console.error('Ошибка отправки данных');
      }
    }

    return { formMap, loading, handleSubmit, isFormChanged }
  }
}
</script>
