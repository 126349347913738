import Axios from "axios";
import { store } from "@/store";

export function resourcesData() {
  // Загрузка файлов
  async function uploadFile(fileBase64, dir = '') {
    try {
      return await Axios.post(`/resources`, { file: fileBase64, dir });
    } catch (e) {
      store.commit('setError', e, { root: true })
    }
  }

  async function downloadFile(url) {
    try {
      return await Axios.get(url );
    } catch (e) {
      store.commit('setError', e, { root: true })
      return null;
    }
  }

  return { uploadFile, downloadFile }
}
