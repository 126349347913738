export function validatorFunctions() {

  async function periodValidator(rule, value) {
    if (value === undefined || value === null) {
      return Promise.reject('Пожалуйста, укажите время для устранения ошибок');
    } else if (+value === 0) {
      return Promise.reject('Пожалуйста, введите количество дней больше 0');
    } else if (+value > 365) {
      return Promise.reject('Нельзя установить больше года');
    } else {
      return Promise.resolve();
    }
  }

  async function phoneValidator(rule, value) {
    const valueInt = parseInt(value);
    if (value === undefined || value === null) {
      return Promise.reject('Пожалуйста, укажите номер телефона');
    } else if (`${valueInt}`.length !== 11) {
      return Promise.reject('Введите номер в 10-значном формате');
    } else {
      return Promise.resolve();
    }
  }

  async function attributesValidator(attributes, valueIsRequired = false) {
    let allAttributesCorrect = true;
    attributes.forEach((node) => {
      if (!node.name) allAttributesCorrect = false;
      if (valueIsRequired && node?.type !== 'group'
        && (
          node?.attributeType === 'number' && (Number.isNaN(node.value) || node.value === null || node.value === "") || (node?.attributeType === 'string' && node.value?.length === 0)
        )
      ) allAttributesCorrect = false;
      if (node?.attributes) {
        node.attributes.forEach((leaf) => {
          if (!leaf.name) allAttributesCorrect = false;
          if (valueIsRequired && (
            leaf?.attributeType === 'number' && (Number.isNaN(leaf.value) || leaf.value === null || leaf.value === "") || (leaf?.attributeType === 'string' && leaf.value?.length === 0)
          )) allAttributesCorrect = false;
        });
      }
    });
    return allAttributesCorrect;
  }

  async function svgMapValidator(attributes) {
    let allAttributesCorrect = true;
    attributes.forEach((node) => {
      // console.log(node);
      // if (!node.objectCode) allAttributesCorrect = false;
      if (!node.mapObjectId) allAttributesCorrect = false;
    });
    return allAttributesCorrect;
  }

  return { periodValidator, phoneValidator, attributesValidator, svgMapValidator }

}
